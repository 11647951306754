// import React from "react";

// function OfficeDetails() {
//   return (
//     <div className="col-md-6 col-sm-12">
//       <div className="row pt-2">
//         <div className="col-12">
//           <h2 className="yellow-border-bt-4px pb-2 font-weight700 text-u text-start effect-underline">
//             <a href="" className="effect-underline ourtools_head">
//               Our Offices
//             </a>
//           </h2>
//         </div>
//       </div>
//       <div className="row">
//         <div className="col-md-6">
//           <h3 className="ourtool_slide_title mt-0 mb-3">Corporate office</h3>

//           <ul className="p-0 ourtool_slide_para">
//             <li>
//               {/* <a href=""> */}
//               <div className="d-flex gap-2 align-items-center">
//                 <span>
//                   <i className="fa-solid fa-location-dot"></i>
//                 </span>
//                 <span>
//                   1002, 1004, Antriksh Bhawan, 22 KG Marg, New Delhi-110001
//                   India
//                 </span>
//               </div>
//               {/* </a> */}
//             </li>
//             <li>
//               {/* <a href=""> */}
//               <div className="d-flex gap-2 align-items-center">
//                 <span>
//                   <i className="fa fa-phone"></i>
//                 </span>
//                 <span>+91 11 66666999</span>
//               </div>
//               {/* </a> */}
//             </li>
//             <li>
//               {/* <a href=""> */}
//               <div className="d-flex gap-2 align-items-center">
//                 <span>
//                   <i className="fa-solid fa-fax"></i>
//                 </span>
//                 <span>+91 11 66666999</span>
//               </div>
//               {/* </a> */}
//             </li>
//           </ul>
//         </div>
//         <div className="col-md-6">
//           <h3 className="ourtool_slide_title mt-0 mb-0 mb-3">
//             Operations office
//           </h3>

//           <ul className="p-0 ourtool_slide_para">
//             <li>
//               {/* <a href=""> */}
//               <div className="d-flex gap-2 align-items-center">
//                 <span>
//                   <i className="fa-solid fa-location-dot"></i>
//                 </span>
//                 <span>
//                   2F-CS, 46-50, Ansal Plaza, Sector-1, Vaishali,
//                   Ghaziabad-201010 (UP), India
//                 </span>
//               </div>
//               {/* </a> */}
//             </li>
//             <li>
//               {/* <a href=""> */}
//               <div className="d-flex gap-2  align-items-center">
//                 <span>
//                   <i className="fa fa-phone"></i>
//                 </span>
//                 <span>+91 11 66666900</span>
//               </div>
//               {/* </a> */}
//             </li>
//             <li>
//               {/* <a href=""> */}
//               <div className="d-flex gap-1 align-items-center">
//                 <span>
//                   <i className="fa-solid fa-fax"></i>
//                 </span>
//                 <span>+91 11 66666932</span>
//               </div>
//               {/* </a> */}
//             </li>
//           </ul>
//         </div>
//       </div>
//       <div className="row">
//         <div className="col-md-12">
//           <div className="map fix mx-3">
//             <iframe
//               src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14005.895442972902!2d77.333196!3d28.645527!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390cfad7fce036b3%3A0x953c46e08641fbfd!2sSmarTech%20Energy%20Management%20Services%20Pvt.%20Ltd.!5e0!3m2!1sen!2sin!4v1707483246647!5m2!1sen!2sin"
//               width="100%"
//               height="363"
//               style={{ border: 0 }}
//               allowFullScreen=""
//               loading="lazy"
//               referrerPolicy="no-referrer-when-downgrade"
//             ></iframe>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }

// export default OfficeDetails;
import React from "react";
import SectionHeader from "../Section/officeHeading"; // Updated import path for SectionHeader component
import SectionAddress from "../Section/SectionAddress"; // Updated import path for SectionAddress component

function OfficeDetails() {
  return (
    <div
      className="col-md-6 col-sm-12 aos-init aos-animate"
      data-aos="slide-left"
      data-aos-duration="1100"
    >
      <SectionHeader />
      <SectionAddress />
    </div>
  );
}

export default OfficeDetails;
