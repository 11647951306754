import React from "react";
import { useEffect, useState } from "react";
import Aos from "aos";
import { BASE_URL } from "./utils";
import "aos/dist/aos.css";
import Favicon from "react-favicon";
import { Route, Routes } from "react-router-dom";
import Home from "./pages/Home/Home";
import About from "./pages/About/About";
import Contact from "./pages/Contact/Contact";
import Media from "./pages/Media/Media";
import Technology from "./pages/Technology/Tehnology";
import Consultancy from "./pages/Consultancy/Consultancy";
import EnergyAdvisory from "./pages/EnergyAdvisory/EnergyAdvisory";
import MeteringSolutions from "./pages/MeteringSolutions/MeteringSolutions";
// import Career from "./pages/Career/Career";
import SerIT from "./pages/SerIT/SerIT";
import SerConsultancy from "./pages/SerConsultancy/SerConsultancy";
import SerManpower from "./pages/SerManpower/SerManpower";
import Solar from "./pages/Solar/Solar";
function App() {
  const [icon, setIcon] = useState("");
  const fetchData = async () => {
    try {
      const response = await fetch(BASE_URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          query: `{
  headersPages(where: {id:627}) {
    nodes {
     featuredImage {
            node {
              sourceUrl
            }
          }
    }
  }
}`,
        }),
      });
      const responseData = await response.json();
      // console.log(
      //   responseData.data.headersPages.nodes[0].featuredImage.node.sourceUrl
      // );
      setIcon(
        responseData.data.headersPages.nodes[0].featuredImage.node.sourceUrl
      );
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    Aos.init();
    fetchData();
  }, []);

  return (
    <div className="App">
      <Favicon url={icon} />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/consultancy" element={<Consultancy />} />
        <Route path="/energy-advisory" element={<EnergyAdvisory />} />
        <Route path="/information-technology" element={<Technology />} />
        <Route path="/solar" element={<Solar />} />
        <Route path="/metering-solutions" element={<MeteringSolutions />} />
        <Route path="/it-consultancy" element={<SerConsultancy />} />
        <Route path="/it-manpower" element={<SerManpower />} />
        <Route path="/services-it" element={<SerIT />} />
        {/* <Route path="/career" element={<Career />} /> */}
        <Route path="/contact" element={<Contact />} />
        <Route path="/Media" element={<Media />} />
      </Routes>
    </div>
  );
}

export default App;
